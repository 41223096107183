/** @jsxImportSource @emotion/react */
import { useEffect, useRef, useState, useContext, memo } from "react";
import { ScrollingCarousel } from "@trendyol-js/react-carousel";
import Drawer from "@mui/material/Drawer";
import { IconButton, Box, Grid } from "@mui/material";
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { useNavigate, useLocation, useSearchParams, NavLink } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { Typography, ClickAwayListener } from "@mui/material";
import ReactPlayer from 'react-player';
import { useProfile } from '../../hooks/services/Profile';
import { useModellist } from '../../hooks/services/useModels';
import Stories, { WithSeeMore } from "react-insta-stories";
import Modal from 'react-bootstrap/Modal';
// import Tooltip from '@mui/material/Tooltip';
// import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import { Document, Page, pdfjs } from 'react-pdf';
import Button from "../../components/Button";
import AppBar from "../../components/AppBar";
// import AppFooter from "../../components/AppFooter";
import Footer1 from "../../components/Footer/Footer";
import BrandIconCard from "../../components/BrandIconCard";
import { useGetHomeScreenStyles } from "./index.styles";
import { Useseenmodels } from '../../hooks/services/modelseen';
import { usePopupsdatadetails } from '../../hooks/services/usePopupaddsdetails';
import ReebokSVG from "../../icons/reebok";
import AdidasSvg from "../../icons/adidas";
import NikeSVG from "../../icons/nike";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import uuid from "react-uuid";
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import TabView from "../../components/Tabs";
import OfferCard from "../../components/OfferCard";
import Dropdown from "../../components/Dropdown";
import DrawerSearchContent from "./drawerSearchContent";
import { useInterval } from "../../hooks/useInterval";
import { usePopupseenuser } from '../../hooks/services/popupseenuser';
import { useGetNavItems } from "./appbarNav";
import { useHome } from "../../hooks/services/useHome";
import { useBrand } from "../../hooks/services/useBrand";
import { useCategory } from "../../hooks/services/useCategory";
import { useSubCategory } from "../../hooks/services/useSubCategory";
import { useMail } from "../../hooks/services/useMail";
import { useCountry } from "../../hooks/services/useCountry";
import { UserContext } from "../../context/UserContext";
import appLogo from "../../assets/images/svg/appLogo.svg";
import { usePopupseen } from '../../hooks/services/PopupSeen';
import { useStateData } from "../../hooks/services/useStateData";
import { useCountyData } from "../../hooks/services/useCountyData";
import { useCityData } from "../../hooks/services/useCityData";
import { usePopAddslist } from "../../hooks/services/usePopupadds";
import Snackbar from "../../components/Snackbar";
import useGetColors from "../../utils/colors";
import { NO_RESULT } from "../../constants";
import { COMING_SOON } from "../../constants";
import { Notificationconsumer } from "../../hooks/services/notifications";
import ToggleButton from 'react-toggle-button'
import BootstrapSwitchButton from 'bootstrap-switch-button-react'
import { baseUrl } from "../../connections";
import axios from 'axios';

import { Viewer } from '@react-pdf-viewer/core';

// Import the styles
import '@react-pdf-viewer/core/lib/styles/index.css';

const brandSvgs = [NikeSVG, ReebokSVG, AdidasSvg];

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@6.2.2/build/pdf.worker.min.js`;

const MobileHome = () => {

  const location = useLocation();

  let drawerWidth = 240;
  const classes = useGetHomeScreenStyles();
  const navigate = useNavigate();
  const { offerCardMultiColors: colors } = useGetColors();
  const { setPayloadsPopupseen, popupseendata } = usePopupseen()
  const { userDetails, activeBrand, setActiveBrand } = useContext(UserContext);
  const [showDrawer, setShowDrawer] = useState(false);
  const [drawerContentRoute, setDrawerContentRoute] = useState("search");
  const [seconds, setSeconds] = useState(0);
  const [selectedOfferIndex, setSelectedOfferIndex] = useState();
  const [redeemedOfferIndexes, setRedeemedOfferIndexes] = useState([]);
  const offerDetailsCardRef = useRef(null);
  const { data, setPayload } = useHome();
  const { setPayloadsPopupseenuser } = usePopupseenuser()
  const { barndList, brandCount, brandpayload1, setBrandPayload1, setBrandPayload, setUpdateBrandFav, isBrandLoading } = useBrand();
  const { categoryList, setCatPayload, setCatPayload1, payload1 } = useCategory();
  const { subCategoryList = [], setSubCatPayload, setSubParam, setCatParam } = useSubCategory();

  const { mailList, mailCount, isRedeem, redeemMessage, redeemError, mailHTML, setMailPayload, setUpdateMailFav, setUpdateMailRedeem, setMailURL, isMailLoading, redeemmsg } = useMail();
  const [subCatActive, setSubCatActive] = useState('');
  const [subCatActive1, setSubCatActive1] = useState('');
  const { countryList, setCountry } = useCountry();
  const { stateData, setStateData } = useStateData();
  const { countyData, setCountyData } = useCountyData();
  const { cityData, setCityData } = useCityData();
  const [selCountry, setSelCountry] = useState();
  const [selState, setSelState] = useState();
  const [selCounty, setSelCounty] = useState('');
  const [selCity, setSelCity] = useState();
  const [cityactive, setcityactive] = useState('')
  const [offerCard, setOfferCard] = useState({});
  const [selCategory, setSelCategory] = useState('');
  const [selBrand, setSelBrand] = useState([]);
  const [numPages, setNumPages] = useState(1);
  const [show, setShow] = useState('');
  const [isAnimate, setIsAnimate] = useState(true);
  const [tabIndex, setTabIndex] = useState(0);
  const [showVideo, setShowVideo] = useState(false);
  const [redeemmsgs, setredeemmsgs] = useState(false)
  const [showLeftDrawer, setShowLeftDrawer] = useState(true);
  const [showRightDrawer, setShowRightDrawer] = useState(false);
  const [locationCheck, setLocationCheck] = useState(false)
  const [searchParams, setSearchParams] = useSearchParams(window.location.search);
  const [searchCat, setSearchCat] = useState(searchParams.get('cat'))
  const [searchBrand, setSearchBrand] = useState(searchParams.get('brand'))
  const [searchSubCat, setSearchSubCat] = useState(searchParams.get('sub'))
  const { Popupaddsdata, setPayloads } = usePopAddslist();
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const { Setprofile, Profilelist } = useProfile()
  const { setusermodelpayload } = Useseenmodels()
  const { modeldata, setModelPayloads } = useModellist();
  const [openShow, setOpenShow] = useState(0);
  const removeQueryParams = () => {
    searchParams.delete('cat');
    searchParams.delete('brand');
    searchParams.delete('sub');
    setSearchParams(searchParams);
  };

  useEffect(() => {

    setSearchCat(searchParams.get('cat'));
    setSearchBrand(searchParams.get('brand'));
    setSearchSubCat(searchParams.get('sub'));

  }, [searchParams]);

  const [seen, setseen] = useState('')
  const isOfferContentDrawerActive = drawerContentRoute === "offerCard";
  const Popupaddsdata1 = Popupaddsdata?.data
  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  useEffect(() => {
    if (barndList && barndList?.length > 0) {
      onBrandSelect(true, barndList[0]?.uuid)
    }
  }, [barndList])

  let userdata = JSON.parse(localStorage.getItem("user"));
  const { noticationdata, setPayloadsdata, unread } = Notificationconsumer();

  useEffect(() => {
    setPayloadsdata(userdata?.uuid);
  }, [])

  useEffect(() => {
    if (searchCat) {
      setSelCategory(searchCat)
    }
    else if (location?.state?.data?.selCategory) {
      setSelCategory(location?.state?.data?.selCategory)
    }
    else if (categoryList) {
      setSelCategory(selCategory ? selCategory : categoryList[0]?.uuid);
    }
  }, [categoryList, searchCat])

  useEffect(() => {
    const data = {
      id: userdata?.uuid,

    }
    setPayloads(data)
    Setprofile(userdata?.uuid)
    setModelPayloads(userdata?.uuid)


  }, [])

  useEffect(() => {

    if (Profilelist?.data?.pop_up === false) {
      setShow(true)
    }
    else if (Profilelist?.data?.pop_up === true) {
      setShow(false)

    }

  }, [Profilelist])
  const { start, stop } = useInterval((count) => {
    setSeconds(count);
    if (count === 18) {
      setSeconds(0);
      setIsAnimate(true);
      stop();
    }
  });

  useEffect(() => {
    if (location?.state?.data?.selCountry) {
      setSelCountry(location?.state?.data?.selCountry);
      setSelState(location?.state?.data?.selState);
      setSelCounty(location?.state?.data?.selCounty);
      setSelCity(location?.state?.data?.selCity);
      setSelCity(cityactive === true ? selCity : location?.state?.data?.selCity);
    }
    else {
      setSelCountry(userDetails?.address?.country || userDetails?.country);
      setSelState(userDetails?.address?.state || userDetails?.state);
      setSelCounty(userDetails?.address?.county || userDetails?.county);
      setSelCity(userDetails?.address?.city || userDetails?.city);
      setSelCity(cityactive === true ? selCity : userDetails?.address?.city || userDetails?.city);
    }
  }, [userDetails, mailList, mailCount])

  const isMobile = window.innerWidth <= 768;

  const carouselContainerStyle = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
    width: '50%',
    maxWidth: isMobile ? '70%' : '600px',
    margin: 'auto',
    flexDirection: isMobile ? 'column' : 'row',
  };

  const modalStyles = {
    overlay: {
      position: 'fixed',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      background: 'rgba(0, 0, 0, 0.5)',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    content: {
      background: 'white',
      padding: '20px',
      borderRadius: '8px',
      position: 'relative',
      width: '80%',
      maxWidth: '600px',
    },
    closeButton: {
      position: 'absolute',
      top: '55px',
      // right: '10px',
      left: '117%',
      background: 'none',
      border: 'none',
      fontSize: '24px',
      cursor: 'pointer',
    },
    Closebutton: {
      position: 'absolute',
      top: '10px',
      right: '10px',
      // left: '117%',
      background: 'none',
      border: 'none',
      fontSize: '30px',
      cursor: 'pointer',
    },
    body: {
      textAlign: 'center',
    },
    image: {
      maxWidth: '100%',
      backgroundColor: 'black',
      height: 'auto',
    },
  };


  const imageStyle1 = {
    width: '50%',
    maxWidth: '50%',
    height: 'auto',
  };

  const arrowButtonStyle = {
    position: 'absolute',
    top: isMobile ? 'auto' : '50%',
    bottom: isMobile ? '10px' : 'auto',
    transform: isMobile ? 'none' : 'translateY(-50%)',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    color: 'white',
    border: 'none',
    padding: isMobile ? '5px' : '10px',
    cursor: 'pointer',
    zIndex: 2,
  };

  const prevButtonStyle = {
    ...arrowButtonStyle,
    left: isMobile ? '10px' : '10px',
  };

  const nextButtonStyle = {
    ...arrowButtonStyle,
    right: isMobile ? '10px' : '10px',
  };

  const image = {
    display: "block",
    maxWidth: "60%",
    // maxHeight: "70%",
    marginLeft: "25%",
    borderRadius: 4
  };
  const imageStyle = {
    cursor: 'pointer', // Ensure the image has a pointer cursor
    // Add other styles as needed
  };
  const handleClose = (item) => {
    setShow(false);

    setPayloadsPopupseen({
      user_uuid: userdata?.uuid
    })
    setusermodelpayload({
      // uuid
      user_uuid: userdata?.uuid,
      modal_uuid: item
    })
    Popupaddsdata1.forEach((item) => {
      setPayloadsPopupseenuser({
        // uuid
        user_uuid: userdata?.uuid,
        popup_uuid: item?.uuid
      })
    })
    setOpenShow(openShow + 1)
  }
  const goToNextAd = (data) => {
    // console.log(data, "datadata454tter")
    // setCurrentAdIndex((prevIndex) => (prevIndex + 1) % Popupaddsdata1?.length);
    setusermodelpayload({
      // uuid
      user_uuid: userdata?.uuid,
      modal_uuid: data
    })
  };

  useEffect(() => {
    let indexValue = 0
    // const defBrandPayload = `pagenum=0&limit=${drawerContentRoute === 'search' && showDrawer ? 100 : 12}&user_uuid=${userDetails?.uuid}`
    // setBrandPayload(`${defBrandPayload}&category_uuid=${selCategory}&country_uuid=${selCountry}&state_uuid=${selState}&city_uuid=${selCity}&county_uuid=${selCounty}`)

    if (subCategoryList && subCategoryList?.length > 0) {
      const isEven = (value) => value.is_mail_available === "true";
      const checkMail = subCategoryList.filter(isEven);
      if (checkMail?.length > 0) {
        subCategoryList && subCategoryList.find((val, undex) => {
          if (searchSubCat !== '') {
            if (searchSubCat === val?.uuid) {
              setSubCatActive(searchSubCat)
              setTabindexs(undex)
              const defPayload = `country_uuid=${selCountry}&state_uuid=${selState}&city_uuid=${selCity}&user_uuid=${userDetails?.uuid}&county_uuid=${selCounty}`;
              if (cityactive === true) {
                setCatPayload(`search=&user_uuid=${userDetails?.uuid === undefined ? "" : userDetails?.uuid}&city_uuid=${selCity}`);
                setMailPayload(selBrand.length > 0 ? selCategory ? `${defPayload}&category_uuid=${selCategory}&brand_uuid=&sub_category_uuid=&type=filter&search=&pagenum=&limit=&seen=${seen}` : `${defPayload}&brand_uuid=${selBrand}&sub_category_uuid=${searchSubCat}&type=filter&search=&pagenum=&limit=&seen=${seen}` : `${defPayload}&sub_category_uuid=${searchSubCat}&category_uuid=${selCategory}&type=filter&search=&pagenum=&limit=&seen=${seen}`);
              } else {
                // setMailPayload(selBrand.length > 0 ? selCategory ? `${defPayload}&category_uuid=${selCategory}&brand_uuid=${selBrand}&sub_category_uuid=${searchSubCat}&seen=${seen}` : `${defPayload}&brand_uuid=${selBrand}&sub_category_uuid=${searchSubCat}&seen=${seen}` : `${defPayload}&sub_category_uuid=${searchSubCat}&category_uuid=${selCategory}&search=&pagenum=&limit=&seen=${seen}`);
                setCatPayload(`search=&user_uuid=${userDetails?.uuid === undefined ? "" : userDetails?.uuid}`);
              }

            }
          }
          else if (val.is_mail_available === "true" && indexValue === 0) {
            indexValue = indexValue + 1
            setSubCatActive(val?.uuid || subCategoryList[0].uuid);
            setTabindexs(undex)
            if (cityactive === true) {
              // ${selBrand}
              // ${val?.uuid || subCategoryList[0].uuid}
              const defPayload = `country_uuid=${selCountry}&state_uuid=${selState}&city_uuid=${selCity}&user_uuid=${userDetails?.uuid}&county_uuid=${selCounty}`;
              setCatPayload(`search=&user_uuid=${userDetails?.uuid === undefined ? "" : userDetails?.uuid}&city_uuid=${selCity}`);
              setMailPayload(selBrand.length > 0 ? selCategory ? `${defPayload}&category_uuid=${selCategory}&brand_uuid=&sub_category_uuid=&type=filter&search=&pagenum=&limit=&seen=${seen}` : `${defPayload}&brand_uuid=${selBrand}&sub_category_uuid=${val?.uuid || subCategoryList[0].uuid}&type=filter&search=&pagenum=&limit=&seen=${seen}` : `${defPayload}&sub_category_uuid=${val?.uuid || subCategoryList[0].uuid}&category_uuid=${selCategory}&type=filter&search=&pagenum=&limit=&seen=${seen}`);
            } else {
              const defPayload = `country_uuid=${selCountry}&state_uuid=${selState}&city_uuid=${selCity}&user_uuid=${userDetails?.uuid}&county_uuid=${selCounty}`;
              setCatPayload(`search=&user_uuid=${userDetails?.uuid === undefined ? "" : userDetails?.uuid}`);
              setMailPayload(selBrand.length > 0 ? selCategory ? `${defPayload}&category_uuid=${selCategory}&brand_uuid=${selBrand}&sub_category_uuid=${val?.uuid || subCategoryList[0].uuid}&seen=${seen}` : `${defPayload}&brand_uuid=${selBrand}&sub_category_uuid=${val?.uuid || subCategoryList[0].uuid}&seen=${seen}` : `${defPayload}&sub_category_uuid=${val?.uuid || subCategoryList[0].uuid}&category_uuid=${selCategory}&search=&pagenum=&limit=&seen=${seen}`);
            }


          }
        });
      }
      else {
        setSubCatActive(subCategoryList[0].uuid);
        setTabindexs(0)
        const defPayload = `country_uuid=${selCountry}&state_uuid=${selState}&city_uuid=${selCity}&user_uuid=${userDetails?.uuid}&county_uuid=${selCounty}`;
        if (cityactive === true) {
          setMailPayload(selBrand.length > 0 ? selCategory ? `${defPayload}&category_uuid=${selCategory}&brand_uuid=&sub_category_uuid=&type=filter&search=&pagenum=&limit=&seen=${seen}` : `${defPayload}&brand_uuid=${selBrand}&sub_category_uuid=${subCategoryList[0].uuid}&type=filter&search=&pagenum=&limit=&seen=${seen}` : `${defPayload}&sub_category_uuid=${subCategoryList[0].uuid}&category_uuid=${selCategory}&type=filter&search=&pagenum=&limit=&seen=${seen}`);
          setCatPayload(`search=&user_uuid=${userDetails?.uuid === undefined ? "" : userDetails?.uuid}&city_uuid=${selCity}`);
        } else {
          setMailPayload(selBrand.length > 0 ? selCategory ? `${defPayload}&category_uuid=${selCategory}&brand_uuid=${selBrand}&sub_category_uuid=${subCategoryList[0].uuid}&seen=${seen}` : `${defPayload}&brand_uuid=${selBrand}&sub_category_uuid=${subCategoryList[0].uuid}&seen=${seen}` : `${defPayload}&sub_category_uuid=${subCategoryList[0].uuid}&category_uuid=${selCategory}&search=&pagenum=&limit=&seen=${seen}`);
          setCatPayload(`search=&user_uuid=${userDetails?.uuid === undefined ? "" : userDetails?.uuid}`);
        }

      }
    }
  }, [subCategoryList, selBrand, selCategory, searchSubCat])

  useEffect(() => {
    // if (cityactive === true) {
    if (cityactive === true) {
      setSubCatPayload(`country_uuid=${selCountry ? selCountry : ''}&state_uuid=${selState ? selState : ''}&city_uuid=${selCity ? selCity : ''}&county_uuid=${selCounty ? selCounty : ''}&search=`);
      setPayload(`page_name=home`);
      setBrandPayload(`pagenum=0&limit=${drawerContentRoute === 'search' && showDrawer ? 100 : 16}&user_uuid=${userDetails?.uuid}&category_uuid=${selCategory ? selCategory : ''}&country_uuid=${selCountry}&state_uuid=${selState}&city_uuid=${selCity}&county_uuid=${selCounty}&brand_uuid=`);
      // ${selBrand ? selBrand : ''}
      setCatPayload(`search=&user_uuid=${userDetails?.uuid === undefined ? "" : userDetails?.uuid}&city_uuid=${selCity}`);
      setCountry(`page=0&limit=10&search=UnitedStates`);
    } else {
      setSubCatPayload(`country_uuid=${selCountry ? selCountry : ''}&state_uuid=${selState ? selState : ''}&city_uuid=${selCity ? selCity : ''}&county_uuid=${selCounty ? selCounty : ''}&search=`);
      setPayload(`page_name=home`);
      setBrandPayload(`pagenum=0&limit=${drawerContentRoute === 'search' && showDrawer ? 100 : 16}&user_uuid=${userDetails?.uuid}&category_uuid=${selCategory ? selCategory : ''}&country_uuid=${selCountry}&state_uuid=${selState}&city_uuid=${selCity}&county_uuid=${selCounty}&brand_uuid=${selBrand ? selBrand : ''}`);
      setCatPayload(`search=&user_uuid=${userDetails?.uuid === undefined ? "" : userDetails?.uuid}`);
      setCountry(`page=0&limit=10&search=UnitedStates`);
    }
    // if (subCatActive && (selCountry || selState || selCounty || selCity)) setMailPayload(`country_uuid=${selCountry}&brand_uuid=${selBrand}&state_uuid=${selState}&city_uuid=${selCity}&sub_category_uuid=${subCatActive}&user_uuid=${userDetails?.uuid}&county_uuid=${selCounty}&category_uuid=${selCategory}&type=filter`);
  }, [selCountry, selState, selCounty, selCity, selCategory, userDetails]);




  const onTabChane = (sub_category_uuid, tabsss) => {
    // setSearchSubCat('')
    setSubCatActive(sub_category_uuid);
    const defUrl = `country_uuid=${selCountry}&state_uuid=${selState}&city_uuid=${selCity}&sub_category_uuid=${sub_category_uuid}&user_uuid=${userDetails?.uuid}&county_uuid=${selCounty}`;
    setMailPayload(selBrand?.length > 0 ? `${defUrl}&brand_uuid=${selBrand}&category_uuid=${selCategory}&seen=${seen}` : selCategory ? `${defUrl}&category_uuid=${selCategory}&seen=${seen}` : `${defUrl}`)
  };

  useEffect(() => {
    if (isOfferContentDrawerActive) {
      start();
    }
  }, [isOfferContentDrawerActive]);


  useEffect(() => {
    if (isRedeem) {
      setShowDrawer(true)
    }
  }, [redeemError, isRedeem])

  useEffect(() => {
    if (!showDrawer) {
      setBrandPayload(`pagenum=0&limit=6&user_uuid=${userDetails?.uuid}&category_uuid=${selCategory ? selCategory : ''}&country_uuid=${selCountry}&state_uuid=${selState}&city_uuid=${selCity}&county_uuid=${selCounty}&brand_uuid=${selBrand ? selBrand : ''}`);
    }
  }, [showDrawer])
  const handleNavLinksClick = (route, item) => {

    setBrandPayload(`pagenum=0&limit=${drawerContentRoute === 'search' && showDrawer ? 100 : 6}&user_uuid=${userDetails?.uuid}&category_uuid=${selCategory ? selCategory : ''}&country_uuid=${selCountry}&state_uuid=${selState}&city_uuid=${selCity}&county_uuid=${selCounty}&brand_uuid=${selBrand ? selBrand : ''}`);
    setCatPayload(`search=&user_uuid=${userDetails?.uuid === undefined ? "" : userDetails?.uuid}`);

    if (item?.mime_type === 'text/html') {
      const url = item?.image_url?.split('/');
      setMailURL({ html_url: url.slice(-1)[0] })
    }

    setSeconds(1)

    setIsAnimate(false);
    setOfferCard(item);

    if (route === 'offerCard') {
      setDrawerContentRoute(route);
    }
    else if (route === 'location' || route === 'search') {
      setDrawerContentRoute(route);
      setShowDrawer(true);
      setBrandPayload(`pagenum=0&limit=${route === 'search' ? 100 : 6}&user_uuid=${userDetails?.uuid}&category_uuid=${selCategory ? selCategory : ''}&country_uuid=${selCountry}&state_uuid=${selState}&city_uuid=${selCity}&county_uuid=${selCounty}&brand_uuid=${selBrand ? selBrand : ''}`);
    }
    {
      item?.type === "regular" &&
        setUpdateMailRedeem({
          user_uuid: userDetails?.uuid,
          mail_uuid: item?.uuid
        });
    }
    {
      item?.type !== "regular" &&
        setUpdateMailRedeem({
          user_uuid: userDetails?.uuid,
          mail_uuid: item?.uuid
        });
    }
  };
  const toggleDrawer = (event) => {
    if (
      (event.type === "keydown" &&
        (event.key === "Tab" || event.key === "Shift")) ||
      isOfferContentDrawerActive
    ) {
      return;
    }
    setShowDrawer(false);
  };

  const onFavouriteToggle = (e, brand_uuid) => {
    e.preventDefault();
    setUpdateBrandFav({
      user_uuid: userDetails?.uuid,
      brand_uuid: brand_uuid
    });

  }

  const onFavouriteMailToggle = (e, mail_uuid, item) => {
    e.preventDefault();
    if (locationCheck) setLocationCheck(false);
    if (item?.city_uuid !== '') {
      if ((item?.country_uuid === userDetails?.address?.country) && (item?.state_uuid === userDetails?.address?.state) &&
        (item?.county_uuid === userDetails?.address?.county) && (item?.city_uuid === userDetails?.address?.city)) {
        setUpdateMailFav({
          user_uuid: userDetails?.uuid,
          mail_uuid: mail_uuid
        });
      }
      else {
        setLocationCheck(true)
      }
    }
    else if (item?.county_uuid !== '') {
      if ((item?.country_uuid === userDetails?.address?.country) && (item?.state_uuid === userDetails?.address?.state) &&
        (item?.county_uuid === userDetails?.address?.county)) {
        setUpdateMailFav({
          user_uuid: userDetails?.uuid,
          mail_uuid: mail_uuid
        });
      }
      else {
        setLocationCheck(true)
      }
    }
    else if (item?.state_uuid !== '') {
      if ((item?.country_uuid === userDetails?.address?.country) && (item?.state_uuid === userDetails?.address?.state)) {
        setUpdateMailFav({
          user_uuid: userDetails?.uuid,
          mail_uuid: mail_uuid
        });
      }
      else {
        setLocationCheck(true)
      }
    }
    else if (item?.country_uuid !== '') {
      if (item?.country_uuid === userDetails?.address?.country) {
        setUpdateMailFav({
          user_uuid: userDetails?.uuid,
          mail_uuid: mail_uuid
        });
      }
      else {
        setLocationCheck(true)
      }
    }
  }

  const onChangeHandler = (id, val) => {
    if (id === 'country') {
      setSelCountry(val);
      setStateData(val);
      setSelState('');
      setSelCity('');
      setSelCounty('');

    }
    if (id === 'state') {
      setSelState(val);
      setSelCounty('');
      setSelCity('');
      setCountyData(val);
    }
    if (id === 'county') {
      setSelCounty(val);
      setSelCity('');
      setCityData(val);
    }
    if (id === 'city') setSelCity(val);

  };
  const [isFirstSelection, setIsFirstSelection] = useState(true);

  const onChangeCategoryHandler = (ss, value) => {
    console.log(value, ss, "asdagyfayd")
    const isExist = selCategory === value ? true : false;
    setSelBrand([]);
    setSearchSubCat('')
    setSelCategory((prev) => prev !== value ? value : prev);
    setSubParam('')
    if (isFirstSelection) {
      setCatParam(value);
      setIsFirstSelection(false);
    } else {
      setCatParam((prev) => prev !== value ? value : prev);
    }
    if (showDrawer) setShowDrawer(false);
    const defBrandPayload = `pagenum=0&limit=${drawerContentRoute === 'search' && showDrawer ? 100 : 6}&user_uuid=${userDetails?.uuid}`
    const defPayload = `country_uuid=${selCountry}&state_uuid=${selState}&city_uuid=${selCity}&sub_category_uuid=${subCatActive}&user_uuid=${userDetails?.uuid}&county_uuid=${selCounty}`
    // setMailPayload(!isExist ? `${defPayload}&category_uuid=${value}&brand_uuid=${selBrand}` : `${defPayload}&brand_uuid=${selBrand}`)
    // setBrandPayload(!isExist ? `${defBrandPayload}&category_uuid=${value}&country_uuid=${selCountry}&state_uuid=${selState}&city_uuid=${selCity}&county_uuid=${selCounty}` : `${defBrandPayload}&country_uuid=${selCountry}&state_uuid=${selState}&city_uuid=${selCity}&county_uuid=${selCounty}&category_uuid=${value}`)
    // setBrandPayload(`pagenum=0&limit=${drawerContentRoute === 'search' && showDrawer ? 100 : 6}&user_uuid=${userDetails?.uuid}&category_uuid=${value}&country_uuid=${selCountry}&state_uuid=${selState}&city_uuid=${selCity}&county_uuid=${selCounty}&brand_uuid=${selBrand ? selBrand : ''}`);
    // setSubCatPayload(`country_uuid=${selCountry}&state_uuid=${selState}&city_uuid=${selCity}&county_uuid=${selCounty}&search=`);
    // setCatPayload(`search=&user_uuid=${userDetails?.uuid === undefined ? "" : userDetails?.uuid}`);
    if (cityactive === true) {
      setMailPayload(!isExist ? `${defPayload}&brand_uuid=&type=filter&search=&pagenum=&limit=` : `${defPayload}&brand_uuid=&type=filter&search=&pagenum=&limit=`)
      setBrandPayload(`pagenum=0&limit=${drawerContentRoute === 'search' && showDrawer ? 100 : 16}&user_uuid=${userDetails?.uuid}&category_uuid=${value}&country_uuid=${selCountry}&state_uuid=${selState}&city_uuid=${selCity}&county_uuid=${selCounty}&brand_uuid=${selBrand ? selBrand : ''}&type=filter`);
      setSubCatPayload(`country_uuid=${selCountry}&state_uuid=${selState}&city_uuid=${selCity}&county_uuid=${selCounty}&search=&type=filter`);
      setCatPayload(`search=&user_uuid=${userDetails?.uuid === undefined ? "" : userDetails?.uuid}&user_uuid=${userDetails?.uuid}`);
    } else {
      setMailPayload(!isExist ? `${defPayload}&category_uuid=${value}&brand_uuid=` : `${defPayload}&brand_uuid=&search=&pagenum=&limit=`)
      setBrandPayload(!isExist ? `${defBrandPayload}&category_uuid=${value}&country_uuid=${selCountry}&state_uuid=${selState}&city_uuid=${selCity}&county_uuid=${selCounty}` : `${defBrandPayload}&country_uuid=${selCountry}&state_uuid=${selState}&city_uuid=${selCity}&county_uuid=${selCounty}&category_uuid=${value}`)
      setBrandPayload(`pagenum=0&limit=${drawerContentRoute === 'search' && showDrawer ? 100 : 16}&user_uuid=${userDetails?.uuid}&category_uuid=${value}&country_uuid=${selCountry}&state_uuid=${selState}&city_uuid=${selCity}&county_uuid=${selCounty}&brand_uuid=${selBrand ? selBrand : ''}`);
      setSubCatPayload(`country_uuid=${selCountry}&state_uuid=${selState}&city_uuid=${selCity}&county_uuid=${selCounty}&search=`);
      setCatPayload(`search=&user_uuid=${userDetails?.uuid === undefined ? "" : userDetails?.uuid}`);
    }

    removeQueryParams();
  }

  useEffect(() => {
    const data = payload1 + "dd"
    setCatPayload1(data)
    const data1 = brandpayload1 + "dd"
    setBrandPayload1(data1)
    if (cityactive !== true) {
      setCatPayload(`search=&user_uuid=${userDetails?.uuid === undefined ? "" : userDetails?.uuid}`);
      setBrandPayload(`pagenum=0&limit=${drawerContentRoute === 'search' && showDrawer ? 100 : 6}&user_uuid=${userDetails?.uuid}&category_uuid=${selCategory}&country_uuid=${selCountry}&state_uuid=${selState}&city_uuid=${selCity}&county_uuid=${selCounty}&brand_uuid=${selBrand ? selBrand : ''}`);

    }

    if (categoryList?.length > 0) {
      setSelCategory(selCategory ? selCategory : categoryList[0]?.uuid);
    }
    // onBrandSelect(true, barndList[0]?.uuid)
  }, [isRedeem])

  useEffect(() => {
    if (searchBrand) {
      const brand = [searchBrand];
      setSelBrand([...brand]);
      setTimeout(function () {
        onBrandSelect(true, searchBrand);
      }, 800);
    }
    else if (location?.state) {
      const brand = [location?.state?.id];
      setSelBrand([...brand]);
      setTimeout(function () {
        onBrandSelect(true, location?.state?.id);
      }, 800);
    }
    else if (activeBrand && activeBrand?.length > 0) {
      const brand = [activeBrand];
      setSelBrand([...brand]);
      setTimeout(function () {
        onBrandSelect(true, activeBrand);
      }, 800);
    }
    else if (selBrand && selBrand?.length > 0) {
      setTimeout(function () {
        onBrandSelect(true, selBrand[0]);
      }, 800);
    }
  }, [selCountry, searchBrand])

  const onBrandSelect = (e, brandId, brandDetails) => {
    const brandList = [...selBrand];
    const brand = e ? [brandId] : selBrand.filter((el) => el !== brandId);
    setSelBrand([...brand]);
    setSubParam([...brand])
    if (searchCat) {
      setCatParam(searchCat ? searchCat : '')
    }
    else {
      setCatParam(selCategory ? selCategory : '')
    }
    setTimeout(mailFilter, 1000);
  }

  const mailFilter = () => {
    if (mailList.length > 0) {
      const id = mailList[0]?.deals_type[0]?.uuid;
      const index = subCategoryList.findIndex((el) => el.uuid === id);
    }
  }


  const DefaultAddress = () => {
    setStateData(userDetails?.address?.country);
    if (userDetails?.address?.city != selCity) {
      setSelCity(cityactive === true ? selCity : userDetails?.address?.city);
    }
    if (userDetails?.address?.state != selState) {
      setCountyData(userDetails?.address?.state);
      setSelState(userDetails?.address?.state);
    }
    if (userDetails?.address?.county != selCounty) {
      setCityData(userDetails?.address?.county);
      setSelCounty(userDetails?.address?.county);
    }

    navigate(location.pathname, { replace: true });
  }
  const [currentIndex, setCurrentIndex] = useState(0);
  const currentCard = mailList[currentIndex];
  console.log(redeemError, currentCard?.is_seen, currentCard?.type, "categoryList45434fdg645dg")

  console.log(currentCard, "currentCard3435")
  const [mailscroll, setmailscroll] = useState(false)
  console.log(currentIndex, mailList, "mailListmailList73457fgdf")

  const nextImage = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % mailList.length);
    setmailscroll(true)
    const currentCard = mailList[currentIndex + 1];
    console.log(currentCard?.uuid, "currentCard43645tryrthjghjpppp6fh")

    {
      currentCard?.type !== "regular" &&
        setUpdateMailRedeem({
          user_uuid: userDetails?.uuid,
          mail_uuid: currentCard?.uuid
        });
      setredeemmsgs(currentCard?.is_seen)

      setTimeout(() => {
        setredeemmsgs(false)
      }, 4500);
    }


    // currentIndex
  };

  const prevImage = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + mailList.length) % mailList.length);
    setmailscroll(true)

    const currentCard = mailList[currentIndex - 1];
    console.log(currentCard, "currentCard43645tryrthjghjpppp6fh")

    {
      currentCard?.type !== "regular" &&
        setUpdateMailRedeem({
          user_uuid: userDetails?.uuid,
          mail_uuid: currentCard?.uuid
        });
      setredeemmsgs(currentCard?.is_seen)
      setTimeout(() => {
        setredeemmsgs(false)
      }, 4500);
    }
  };

  const [open1, setOpen1] = useState(false);
  console.log(open1, "open1gdfsg5464564")

  const handleClose1 = () => {
    setOpen1(false)
  }
  const handleClickOpen1 = (event) => {
    // sethistoryData(event)
    setOpen1(true);
  };

  const stories2 = Popupaddsdata1?.map((item, index) => ({
    // {console.log()}
    content: ({ action, isPaused }) => (
      <div style={imageStyle} key={index} >

        <img style={image} src={item?.image} alt={`Story ${index}`} />
      </div >
    )
  }));

  const handleStoryStart = (data1, index) => {
    // if (Popupaddsdata) {

    const data = {
      id: userdata?.uuid,
      indexId: index
    }
    setPayloads(data)
    setPayloadsPopupseen({
      user_uuid: userdata?.uuid
    });
    setPayloadsPopupseenuser({
      user_uuid: userdata?.uuid,
      popup_uuid: data1
    });
    // }
  }
  const handleClick1 = (index) => {
    const data = {
      id: userdata?.uuid,
      indexId: index
    }
    setPayloads(data)
    setOpen1(false)

  }

  const VideoPlayer = memo(({ url }) => (

    <div >
      {/* <h1>My Video Player</h1> */}
      <ReactPlayer
        url={url}
        controls
        onError={(e) => console.error('Error occurred while playing video:', e)}
          width='100%'
          height='100%'
      />
    </div>
  ));

  const DrawerLocationCardContent = () => (
    <>
      <div css={[classes.locationDetailsContentWrapper]} ref={offerDetailsCardRef}>
        <Dropdown key={uuid()} label={'Country'} component={'autocomplete'} lists={countryList} value={selCountry} renderKey={'name'} renderValue={'uuid'} onChange={(e, value) => onChangeHandler('country', value)} />
        <Dropdown key={uuid()} label={'State'} component={'autocomplete'} lists={stateData} value={selState} renderKey={'name'} renderValue={'uuid'} onChange={(e, value) => onChangeHandler('state', value)} />
        <Dropdown key={uuid()} label={'County'} component={'autocomplete'} lists={countyData} value={selCounty} renderKey={'name'} renderValue={'uuid'} onChange={(e, value) => onChangeHandler('county', value)} />
        <Dropdown key={uuid()} label={'City'} component={'autocomplete'} lists={cityData} value={selCity} renderKey={'name'} renderValue={'uuid'} onChange={(e, value) => onChangeHandler('city', value)} />
      </div>
      {(userDetails?.address?.city !== selCity || userDetails?.address?.county !== selCounty || userDetails?.address?.state !== selState) &&
        <div css={[classes.locationDetailsContentWrapper]} ref={offerDetailsCardRef}>
          <button css={[classes.buttons]} key={uuid()} onClick={() => DefaultAddress()}>Change to Default Address</button>
        </div>
      }
    </>
  );

  const DrawerOfferCardContent = () => (
    <div css={classes.offerDetailsContentWrapper} ref={offerDetailsCardRef}>
      {offerCard?.type === "paid" && ((redeemError === false && isAnimate) || (redeemError === true) || offerCard?.is_seen === 'true') && (
        <IconButton aria-label="back" size="medium" css={classes.backArrow} onClick={(e) => { setDrawerContentRoute('search'); setShowDrawer(false); setShowVideo(false); }}>
          <ArrowBackIosIcon /> Back
        </IconButton>
      )}
      {offerCard?.type === "regular" && (
        <IconButton aria-label="back" size="medium" css={classes.backArrow} onClick={(e) => { setShowDrawer(false); setShowVideo(false); }}>
          <ArrowBackIosIcon /> Back
        </IconButton>
      )}

      {seconds === 0 ?
        <div>

          <VideoPlayer url={currentCard?.video_url} />
        </div>
        :
        <FontAwesomeIcon icon={faSpinner} className={`fa-pulse fa-spin-reverse fa-1x`} color="#1890ff" />
      }
      <div css={classes.timerWrapper}>
        <div style={{ marginLeft: isMobile ? "0" : "-100px" }}>
          {offerCard?.type === "paid" && redeemError === false && offerCard?.is_seen === "false" && seconds !== 0 && (
            <div className={classes.progress} style={{ position: 'relative' }}>
              <FontAwesomeIcon icon={faSpinner} className={isAnimate ? 'fa-1x' : `fa-pulse fa-spin-reverse fa-1x`} color="#1890ff" />
              <Typography variant="caption" component="div" color="text.secondary" style={{ position: 'absolute', top: '20px', left: '14px' }}>
                <div style={{ marginLeft: "10px", marginTop: "-18px", whiteSpace: "nowrap", textOverflow: "ellipsis", overflow: " hidden" }}>
                  <p>{`${(seconds === 1 ? "Please wait, While your mail is being Redeemed ...." : "Please wait, While your mail is being Redeemed ....")}`}</p>
                </div>
              </Typography>
            </div>
          )}
        </div>
      </div>
      <Typography css={classes.subText}>
        {offerCard?.message}
      </Typography>

      <div style={carouselContainerStyle}>
        {currentIndex !== 0 && (
          <button onClick={prevImage} style={prevButtonStyle} disabled={currentIndex === 0}>
            {"<"}
          </button>
        )}
        {currentCard?.mime_type === 'text/html' && <div style={{ maxWidth: '98%', margin: '0 auto' }} dangerouslySetInnerHTML={{ __html: mailHTML }} />}
        {/* {currentCard?.mime_type === 'application/pdf' && (
          <Document file={currentCard?.image_url} onLoadSuccess={onDocumentLoadSuccess}>
            <Page pageNumber={numPages} />
          </Document>
        )} */}
                {seconds === 0 && currentCard?.mime_type === 'application/pdf' && <Viewer fileUrl={currentCard?.image_url} >
                </Viewer>}
        {currentCard?.mime_type !== 'text/html' && currentCard?.mime_type !== 'application/pdf' && (
          <>
            {currentCard?.image_url === null ? <span>No File Available</span> : <img style={imageStyle1} src={currentCard?.image_url} alt="" />}
          </>
        )}
        {currentIndex !== mailList.length - 1 && (
          <button onClick={nextImage} style={nextButtonStyle}>
            {">"}
          </button>
        )}
      </div>
    </div>
  );

  const renderDrawerContent = () => {
    switch (drawerContentRoute) {
      case "search":
        return <DrawerSearchContent classes={classes} brandList={barndList} mailList={mailList} categoryList={categoryList} onFavouriteToggle={onFavouriteToggle} appLogo={appLogo} onClose={toggleDrawer} onCatSelect={onChangeCategoryHandler} onSelect={onBrandSelect} selBrand={selBrand} showDrawer={() => setShowDrawer(false)} drawer={true} />;

      case "location":
        return <DrawerLocationCardContent />;

      case "offerCard":
        return <DrawerOfferCardContent />;
    }
  };

  const onDrawerHandler = (e) => {
    e.preventDefault();
    setShowLeftDrawer(!showLeftDrawer);
    drawerWidth = 0;
    // onChangeCategoryHandler(selCategory ? selCategory : '');
  }

  const onDrawerNotification = (e) => {

    e.preventDefault()
    removeQueryParams()
    setShowRightDrawer(!showRightDrawer)
  }

  const handleClickAway = () => {
    setShowRightDrawer(false);
  };
  // alert(showRightDrawer)
  const Allarray = [
    { name: "All", uuid: '' }
  ]

  const [tabIndexs, setTabindexs] = useState(0)
  const [change, setchange] = useState(false)
  const [checkseen, setCheckseen] = useState(false)
  const activefunct = (data) => {
    if (data === false) {
      setCheckseen(true)
      setseen('no')
      const defPayload = `country_uuid=${selCountry}&state_uuid=${selState}&city_uuid=${selCity}&user_uuid=${userDetails?.uuid}&county_uuid=${selCounty}`;
      setMailPayload(selBrand.length > 0 ? selCategory ? `${defPayload}&category_uuid=${selCategory}&brand_uuid=${selBrand}&sub_category_uuid=${subCatActive}&seen=no` : `${defPayload}&brand_uuid=${selBrand}&sub_category_uuid=${subCatActive}&seen=no` : `${defPayload}&sub_category_uuid=${subCatActive}&category_uuid=${selCategory}&search=&seen=no`);
    }
    else {
      setCheckseen(false)
      setseen('')
      const defPayload = `country_uuid=${selCountry}&state_uuid=${selState}&city_uuid=${selCity}&user_uuid=${userDetails?.uuid}&county_uuid=${selCounty}`;
      setMailPayload(selBrand.length > 0 ? selCategory ? `${defPayload}&category_uuid=${selCategory}&brand_uuid=${selBrand}&sub_category_uuid=${subCatActive}&seen=''` : `${defPayload}&brand_uuid=${selBrand}&sub_category_uuid=${subCatActive}&seen=''` : `${defPayload}&sub_category_uuid=${subCatActive}&category_uuid=${selCategory}&search=&seen=''`);
    }
    // setseen()
  }


  const initialItemsToShow = 5;
  const [notifications, setNotifications] = useState()
  const [itemsToShow, setItemsToShow] = useState(initialItemsToShow);

  const navigationView = (link) => {
    navigate(link)
    // window.location.reload(false)
  }
  const NotificationUpdateseen = (notifiid) => {
    const payload = {
      "notify_uuid": notifiid
    }
    axios.put(`${baseUrl}/notification/update/seen`, payload, {
      headers: {
        "x-auth-token": userdata && userdata?.token
      }
    })
      .then((res) => {
        console.log(res)
      })
      .catch((err) => {
        console.log(err)
      })
  }
  return (
    <>

      {modeldata?.data && modeldata.data.length > 0 && (
        <Modal show={show && openShow === 0} style={{ width: '100%' }}>
          {modeldata?.data?.map((item) => {
            return <div key="" style={modalStyles.overlay}>
              <div style={modalStyles.content}>
                <button style={modalStyles.Closebutton} onClick={() => handleClose(item?.uuid)}>X</button>
                <div style={modalStyles.body} onClick={() => goToNextAd(item?.uuid)}>
                  <img src={item?.image} alt="MealBox Offer" style={modalStyles.image} />
                </div>
              </div>
            </div>
          })}
        </Modal>
      )}
      {/* <Modal show={open1}  onHide={() => setOpen1(false)} >
        <div >
          {Popupaddsdata1?.length > 0 ? (
            <div className="stories-container" >
              <Stories
                loop
                keyboardNavigation
                defaultInterval={10000}
                stories={stories2}
                // width={232}
                height={350}
                // right={200}
                // bottom={500}
                // onClick={setPayloads(userdata?.uuid)}
                onStoryEnd={(s, st) => handleClick1(s)}
                // onAllStoriesEnd={(s, st) => console.log("all stories ended", s, st)}
                onStoryStart={(s, st) => handleStoryStart(Popupaddsdata1[s].uuid, s)}
                onAllStoriesEnd={(s) => handleClick1(s)}

              />
            </div>
          ) : (
            <div style={{ marginRight: "40%" }}>No Popup Adds</div>
          )}
        </div>

      </Modal> */}
          <Modal
      show={open1}
      onHide={() => setOpen1(false)}
      dialogClassName="custom-modal"
      centered
    >
      <div>
        {Popupaddsdata1?.length > 0 ? (
          <div className="stories-container">
            <Stories
              loop
              keyboardNavigation
              defaultInterval={10000}
              stories={stories2}
              height={300}
              width={280}
              onStoryEnd={(s, st) => handleClick1(s)}
              onStoryStart={(s, st) => handleStoryStart(Popupaddsdata1[s].uuid, s)}
              onAllStoriesEnd={(s) => handleClick1(s)}
            />
          </div>
        ) : (
          <div style={{ textAlign: 'center' }}>No Popup Adds</div>
        )}
      </div>
    </Modal>

      <div css={classes.pageContainer}>
        <AppBar navItems={useGetNavItems({ handleNavLinksClick, selCountry, selState, selCity, setSelCity, setcityactive, selCounty, selCategory, cityData, selBrand, subCatActive, onDrawerNotification, handleClickOpen1, Popupaddsdata })} onDrawerHandler={onDrawerHandler} drawerWidth={drawerWidth} />

        {/* <Box css={classes.flex}> */}
        {/* {categoryList?.length > 0 && <Drawer
          anchor={'left'}
          variant="persistent"
          open={showLeftDrawer}
          css={[classes.sideDrawer, {
            width: drawerWidth,
            [`& .MuiDrawer-paper`]: { width: drawerWidth, boxSizing: 'border-box', marginTop: 80 },
          }]}
          onClose={(e) => setShowLeftDrawer(!showLeftDrawer)}
        >
          {categoryList.map((list, i) => {
            console.log(list, "list")
            return (<ListItem key={i} component="div" disablePadding>
              <ListItemButton selected={selCategory === list.uuid ? true : false} key={i} onClick={(e) => { onChangeCategoryHandler(list.uuid); }} sx={{ '.Mui-selected': { background: '#efefef' } }}>
                <ListItemText primary={`${list.name}`} /><span >{`${list.mails_count === 0 ? "" : list.mails_count}`}</span>
              </ListItemButton>
            </ListItem>)
          })}
        </Drawer>} */}

        <Box
          component="main"
          sx={{ flexGrow: 1, marginLeft: showLeftDrawer ? 0 : `-${drawerWidth}px` }}
        >
          {categoryList?.length > 0 &&
            <div style={{ paddingLeft: 10, paddingRight: 10 }}>
              <select
                value={selCategory}
                onChange={(e) => onChangeCategoryHandler('', e.target.value)}
                style={{
                  width: '100%',
                  height: 45,
                  marginTop: 10,
                  borderColor: '#B3C8CF',
                  borderRadius: 8
                }}
              >
                {categoryList?.map((item, index) => {
                  return <option
                    value={item?.uuid}
                  >
                    {item?.name} {`(${item?.mails_count > 0 ? item?.mails_count : 0})`}
                  </option>
                })}
              </select>
              {/* <Dropdown key={uuid()} label={'Category'} component={'autocomplete'} lists={categoryList} mails_count={'mails_count'} countLabel={true} value={selCategory} renderKey={'name'} renderValue={'uuid'} onChange={(e, value) => onChangeCategoryHandler(e, value)} /> */}
            </div>
          }
          <br />
          {data.length > 0 && (
            <ScrollingCarousel className="scrollingCarousal">
              {data.map((item) => (
                <img key={uuid()} src={`${item?.image}`} css={classes.offerImg} alt="scrollimage" />
              ))}
            </ScrollingCarousel>
          )}
          <div css={classes.brandCardsWrapper}>
            {isBrandLoading === true && (
              <FontAwesomeIcon icon={faSpinner} className="fa-pulse fa-spin-reverse fa-4x" color="#1890ff" />
            )}
            <Grid container spacing={2}>
              {isBrandLoading === false &&
                barndList?.length > 0 &&
                barndList?.map((item, i) => {
                  const BrandIcn = brandSvgs[i % brandSvgs.length];
                  if (!item?.is_active) return null;

                  return (
                    <Grid item xs={4} sm={4} md={4} key={uuid()}>
                      {/* <div css={classes.brandCard} style={{ cursor: 'pointer' }}> */}
                      <BrandIconCard
                        rounded
                        isActive={selBrand?.indexOf(item?.uuid) !== -1 || item.is_highlight === 'true'}
                        onSelect={(e) => onBrandSelect(e, item?.uuid, item)}
                        isFavourite={item?.is_faviourate === 'true'}
                        onFavouriteToggle={(e) => onFavouriteToggle(e, item?.uuid)}
                        size={100}
                        maxSize={100}
                        src={item?.logo_url}
                        src1={item}
                        onClick={() => {
                          setBrandPayload(
                            `pagenum=0&limit=${drawerContentRoute === 'search' && showDrawer ? 100 : 6}&user_uuid=${userDetails?.uuid}&category_uuid=${selCategory ? selCategory : ''}&country_uuid=${selCountry}&state_uuid=${selState}&city_uuid=${selCity}&county_uuid=${selCounty}&brand_uuid=${item.uuid}`
                          );
                          setSubCatPayload(`country_uuid=${selCountry}&state_uuid=${selState}&city_uuid=${selCity}&county_uuid=${selCounty}&search=`);
                          setCatPayload(`search=&user_uuid=${userDetails?.uuid === undefined ? "" : userDetails?.uuid}`);
                        }}
                      >
                        <div css={classes.imageView}>
                          {item?.logo_url && <img src={`${item?.logo_url}`} width="60%" height="auto" style={{ borderRadius: 19 }} />}
                          {item?.icon && <BrandIcn width="95%" height="95%" />}
                          <div>{item?.mails_count === 0 ? "" : item?.mails_count}</div>
                        </div>
                      </BrandIconCard>
                      {/* </div> */}
                    </Grid>
                  );
                })}
            </Grid>

            {brandCount > 6 && (
              <div css={classes.moreBrand}>
                <IconButton
                  aria-label="more"
                  onClick={(e) =>
                    navigate(`/moreBrands?brand_uuid=${selBrand}`, {
                      state: {
                        selCountry,
                        selState,
                        selCity,
                        selCounty,
                        selCategory,
                        selBrand,
                      },
                    })
                  }
                >
                  <ArrowForwardIosIcon />
                </IconButton>
              </div>
            )}
            {brandCount === 0 && isBrandLoading === false && (
              <div css={[classes.noResult]}>
                <p>{COMING_SOON}</p>
              </div>
            )}
          </div>
          <div css={classes.tabsWrapper}>
            <TabView
              tabs={subCategoryList}
              onTabChane={onTabChane}
              subUuid={subCatActive}
              setTabindexs={setTabindexs}
              tabIndex={tabIndexs}
              renderActiveTabContent={() => (
                <>
                  &nbsp; &nbsp;
                  <div>
                    <BootstrapSwitchButton
                      width={100}
                      checked={checkseen}
                      onlabel="Un seen"
                      offlabel="All"
                      value={checkseen}
                      onChange={() => activefunct(checkseen)}
                    />
                  </div>

                  <div css={classes.offerCardsWrapper}>
                    {isMailLoading === true && (
                      <FontAwesomeIcon icon={faSpinner} className="fa-pulse fa-spin-reverse fa-4x" color="#1890ff" />
                    )}
                    <Grid container spacing={2}>
                      {isMailLoading === false &&
                        mailList?.length > 0 &&
                        mailList.map((_item, idx) => {
                          if (!_item?.is_active) return null;
                          let j = idx % 4;

                          return (
                            <Grid spacing={4} xs={6} sm={6} >
                              <OfferCard
                                key={uuid()}
                                containerStyles={{
                                  backgroundColor: colors[j]
                                }}
                                isRedeemed={_item?.is_seen === 'true'}
                                item={_item}
                                maxSize={'98%'}
                                size={'100%'}
                                height={200}
                                maxHeight={200}
                                mobile={true}
                                isFavourite={_item?.is_favourite === 'true'}
                                onFavouriteMailToggle={(e) => onFavouriteMailToggle(e, _item?.uuid, _item)}
                                isLocation={() => setLocationCheck(false)}
                                message={_item?.message}
                                // onClick={() => {
                                //   onChangeCategoryHandler('', selCategory);
                                //   setSelectedOfferIndex(idx);
                                //   handleNavLinksClick("offerCard", _item);
                                //   setCatPayload(`search=&user_uuid=${userDetails?.uuid === undefined ? "" : userDetails?.uuid}`);
                                //   setBrandPayload(
                                //     `pagenum=0&limit=${drawerContentRoute === 'search' && showDrawer ? 100 : 6}&user_uuid=${userDetails?.uuid}&category_uuid=${selCategory ? selCategory : ''}&country_uuid=${selCountry}&state_uuid=${selState}&city_uuid=${selCity}&county_uuid=${selCounty}&brand_uuid=${selBrand ? selBrand : ''}`
                                //   );
                                // }}
                                onClick={() => {
                                  // if (_item?.is_seen === 'true') return;
                                  // onChangeCategoryHandler(selCategory)
                                  // setTimeout(() => {
                                  // setredeemmsgs(_item?.is_seen)
                                  // }, 2500);

                                  setTimeout(() => {
                                    // setredeemmsgs(false)
                                  }, 4500);
                                  const defPayload = `country_uuid=${selCountry}&state_uuid=${selState}&city_uuid=${selCity}&sub_category_uuid=&category_uuid=${selCategory}&user_uuid=${userDetails?.uuid}&county_uuid=${selCounty}`
                                  setMailPayload(`${defPayload}&brand_uuid=&search=&pagenum=&limit=`)

                                  // setmailvideomadel(true)
                                  // setmadelvideo(_item)
                                  setShowVideo(true);
                                  setSelectedOfferIndex(idx);
                                  // setTimeout(() => {
                                  handleNavLinksClick("offerCard", _item);
                                  // }, 3500);

                                  setCatPayload(`search=&user_uuid=${userDetails?.uuid === undefined ? "" : userDetails?.uuid}`);
                                  setBrandPayload(`pagenum=0&limit=${drawerContentRoute === 'search' && showDrawer ? 100 : 16}&user_uuid=${userDetails?.uuid}&category_uuid=${selCategory ? selCategory : ''}&country_uuid=${selCountry}&state_uuid=${selState}&city_uuid=${selCity}&county_uuid=${selCounty}&brand_uuid=${selBrand ? selBrand : ''}`);
                                  // setSubCatPayload(`country_uuid=${selCountry}&state_uuid=${selState}&city_uuid=${selCity}&county_uuid=${selCounty}&search=`)

                                  // if (!redeemedOfferIndexes.includes(idx)) {
                                  //   setSelectedOfferIndex(idx);
                                  //   handleNavLinksClick("offerCard", _item);
                                  // }
                                }}
                              />
                            </Grid>
                          );
                        })}
                    </Grid>
                    {mailCount > 15 && (
                      <div css={[classes.moreMail]}>
                        <IconButton
                          aria-label="more"
                          onClick={(e) =>
                            navigate(
                              `/moreMails?country_uuid=${selCountry}&state_uuid=${selState}&city_uuid=${selCity}&sub_category_uuid=${subCatActive}&user_uuid=${userDetails?.uuid}&county_uuid=${selCounty}&selCategory=${selCategory}&brand_uuid=${selBrand}&type=filter`,
                              {
                                state: {
                                  selCountry,
                                  selState,
                                  selCity,
                                  selCounty,
                                  selCategory,
                                  selBrand,
                                },
                              }
                            )
                          }
                        >
                          <ArrowForwardIosIcon />
                        </IconButton>
                      </div>
                    )}
                    {mailCount === 0 && isMailLoading === false && (
                      <div css={[classes.noResult]}>
                        <p>{NO_RESULT}</p>
                      </div>
                    )}
                  </div>
                </>
              )}
            />
          </div>
        </Box>

        {/* </Box> */}
        <div>
          {/* <AppFooter /> */}
          <Footer1 />
        </div>

        <Drawer
          anchor="top"
          open={showDrawer}
          onClose={toggleDrawer}
          PaperProps={{
            sx: classes.drawer,
          }}
          sx={[classes.drawerContainer, { zIndex: 1277 }]}
        >
          {renderDrawerContent()}
        </Drawer>
        {isRedeem && <Snackbar message={redeemMessage} isOpen={isRedeem} severity={redeemError ? 'error' : 'success'} />}
        {locationCheck && <Snackbar message={"Favourite Not Possible"} isOpen={locationCheck} severity={'error'} />}
        {redeemmsgs && currentCard?.type === "paid" && <Snackbar message={redeemmsg} isOpen={redeemmsgs} severity={'success'} />}
        {showRightDrawer &&
          <ClickAwayListener onClickAway={handleClickAway}>

            <Drawer

              anchor={'right'}
              variant="persistent"
              open={showRightDrawer}
              // onClose={toggleDrawer(anchor, false)}
              ModalProps={{
                onBackdropClick: (e) => setShowRightDrawer(!showRightDrawer)
              }}
              css={[classes.sideDrawer, {
                width: drawerWidth,
                // style={{ width: "326px", marginTop: "117px" }}
                [`& .MuiDrawer-paper`]: { width: drawerWidth, boxSizing: 'border-box', marginTop: 80, width: 330, marginTop: 117, height: 410, overflowX: "hidden" },
              }]}
              onClose={(e) => setShowRightDrawer(!showRightDrawer)}

            >

              <h4 style={{ marginLeft: "41px", marginTop: "28px" }}>Notifications</h4>
              {noticationdata?.slice(0, itemsToShow).map((items) => {
                return (
                  <>
                    {/* onClick={(event) => {navigationView(item.link);NotificationUpdateseen(item?.uuid)}} */}
                    {items?.is_seen === true ?
                      <div className="container" style={{ marginLeft: "11px", cursor: 'pointer' }} onClick={(event) => { setShowRightDrawer(false); navigationView(items.link); NotificationUpdateseen(items?.uuid) }}>
                        <h6 ><span class="dot" style={{ height: "10px", width: "10px", backgroundColor: "green", borderRadius: "50%", display: "inline-block", marginRight: "10px" }}></span>{items.title}</h6>
                        <p style={{ marginLeft: "20px", width: "200px" }}>{items.message}</p>
                      </div>
                      :
                      <div className="container" style={{ marginLeft: "11px", cursor: 'pointer' }} onClick={(event) => { setShowRightDrawer(false); navigationView(items.link); NotificationUpdateseen(items?.uuid) }}>
                        <h6 ><span class="dot" style={{ height: "10px", width: "10px", backgroundColor: "red", borderRadius: "50%", display: "inline-block", marginRight: "10px" }}></span>{items.title}</h6>
                        <p style={{ marginLeft: "20px", width: "200px" }}>{items.message}</p>
                      </div>}
                  </>
                )
              })
              }
              <NavLink to='/Notifications' style={{ color: "green", marginLeft: "76px" }}>
                <u>View More</u>
              </NavLink>
            </Drawer>
          </ClickAwayListener>
        }
      </div>

    </>
  );
};

export default memo(MobileHome);
